<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">黑名單管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增黑名單
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-input
              type="search"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              clearable="true"
              @keyup="
                e => {
                  if (e.$event.keyCode === 13) grid.refresh();
                }
              "
              @clear="grid.refresh()"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <Grid ref="grid" v-bind="gridOptions" @addNewRow="onGridAdd">
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-member-id="{ data }">
                <SelectBox
                  :transfer="true"
                  v-model="data.MemberId"
                  v-bind="memberSelectOptions"
                  :readonly="data.Id"
                />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            >
            </vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
        </Grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Operator } from "@cloudfun/core";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "黑名單",
      canCreate: true,
      canUpdate: true,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true
      },
      columns: [
        {
          field: "CreatedTime",
          title: "鎖定時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "Member.Number",
          title: "會員編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Member.Person.Name",
          title: "會員姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "StartTime",
          title: "開始時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "EndTime",
          title: "結束時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "Description",
          title: "事由",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
              const now = new Date();
              params.condition!.and("IsReleaseNow", Operator.Equal);
              return model.dispatch("ban/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("ban/query") : undefined,
        save: model ? params => model.dispatch("ban/save", params) : undefined
      },
      modalConfig: { width: "60%", height: "auto", showFooter: true }
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        {
          field: "MemberId",
          title: "會員",
          span: 24,
          slots: { default: "column-member-id" }
        },
        {
          field: "StartTime",
          title: "開始時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", placeholder: "請輸入時間" }
          }
        },
        {
          field: "EndTime",
          title: "結束時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", placeholder: "請輸入時間" }
          }
        },
        {
          field: "Description",
          title: "事由",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: {
              placeholder: "請輸入事由"
            }
          }
        }
      ],
      rules: {
        MemberId: [{ required: true }]
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "value",
      placeholder: "選擇會員",
      textField: "label",
      valueField: "value",
      columns: [
        {
          field: "label",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value =>
          new Promise(resolve => {
            model!
              .dispatch("member/find", value)
              .then(res => resolve({ label: res.Person.Name, value: res.Id }));
          }),
        query: params => model!.dispatch("member/options", params) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      memberSelectOptions
    };
  },
  methods: {
    onGridAdd(row: any, callback: any) {
      row.StartTime = new Date();
      row.EndTime = new Date();
      row.EndTime.setMonth(row.EndTime.getMonth() + 1);
      callback();
    }
  }
});
</script>
